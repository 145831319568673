.card {
  .card-header {
    font-weight: bold;
  }
}

.card {
  box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.08) !important;
  &.no-shadow {
    box-shadow: none !important;
  }
}

.alert {
  box-shadow: 0 2px 32px 0px rgba(0, 0, 0, 0.28) !important;
}

ol.breadcrumb {
  margin-bottom: 0;
}

.full-width {
  width: 100%;
}

img {
  object-fit: cover;
}

.btn {
  font-weight: bold !important;
  &.dropdown-toggle {
    font-weight: normal !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

h1 {
  font-weight: 700 !important;
  margin-bottom: 2rem !important;
}

label.form-label {
  opacity: 0.75 !important;
}

.bootstrap-table {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }
}

.icon {
  min-width: 22px;
  min-height: 22px;
  &.in-card-header {
    min-width: 20px;
    min-height: 20px;
    margin-right: 0.2rem;
  }
  &.in-h1 {
    margin-bottom: 0.5rem;
    margin-right: 0.25rem;
  }
  &.in-list {
    margin-bottom: 0.15rem;
    margin-right: 0.25rem;
  }
}

.leaflet-marker-icon {
  background: none !important;
  border: none !important;
}

textarea {
  max-height: 200px;
}
